<script setup lang="ts">
const randomId = ref(Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g :clip-path="`url(#clip0_176_610${randomId})`">
            <path
                d="M16.5479 0H7.4521C5.5488 0 4 1.5488 4 3.4521V4H3.4521C1.5488 4 0 5.5488 0 7.4521V16.5478C0 18.4512 1.5488 20 3.4521 20H12.5478C14.2963 20 15.73 18.6882 15.9542 17H16.5478C18.4512 17 20 15.4512 20 13.5479V3.4521C20 1.5488 18.4512 0 16.5479 0ZM18 13.5479C18 14.3486 17.3486 15 16.5479 15H16V7.4521C16 5.5488 14.4512 4 12.5479 4H6V3.4521C6 2.6514 6.6514 2 7.4521 2H16.5478C17.3486 2 18 2.6514 18 3.4521V13.5479Z"
                :fill="`url(#paint0_linear_176_610${randomId})`"
            />
        </g>
        <defs>
            <linearGradient
                :id="`paint0_linear_176_610${randomId}`"
                x1="10"
                y1="0"
                x2="10"
                y2="20"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath :id="`clip0_176_610${randomId}`">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
